import React from 'react';
import { Link, graphql } from 'gatsby';
import InViewMonitor from 'react-inview-monitor';
import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Markdown from 'react-markdown';
import Layout from '../components/layout';
import SEO from '../components/SEO/';
import PageHeader from '../components/Sections/PageHeader';
import QuickForm from '../components/Sections/QuickForm';

const sectionTitle = 'Servizi';

const ServicesPage = ({
  data: {
    allDatoCmsCategory: category,
    allDatoCmsProject: { distinct: categories },
  },
}) => (
  <Layout>
    <SEO title={sectionTitle} />
    <PageHeader title={sectionTitle} />
    <div id="page" className="section">
      <Container>
        <Row>
          <Col sm={{ size: 12 }}>
            {category.edges.map(category => (
              <InViewMonitor key={category.node.slug} classNameNotInView="animate-box" classNameInView="animated fadeInUp">
                <Row key={category.node.id} id={`servizio-${category.node.slug}`} className="point border-bottom">
                  <Col sm="2">
                    <div className="bg-primary text-center p-4 d-none d-md-block"><img src={require(`../assets/img/svg/${category.node.slug}.svg`)} alt={`${category.node.name}`} className="img-fluid" /></div>
                  </Col>
                  <Col sm="10">
                    <h2 className="text-primary font-weight-bold font-family-base h4">{category.node.name}</h2>
                    <Markdown source={category.node.description} />
                    {categories.includes(category.node.slug)
                      ? (
                        <Link to={`/progetti/tipologia/${category.node.slug}`}>
                          <Button color="primary" outline className="mb-2">
                          Guarda progetti
                          </Button>
                        </Link>
                      ) : ''
                    }
                  </Col>
                </Row>
              </InViewMonitor>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
    <QuickForm />
  </Layout>
);

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesQuery {
    allDatoCmsCategory(sort: {fields: name}) {
      edges {
        node {
          id
          name
          slug
          description
        }
      }
    }
    allDatoCmsProject {
      distinct(field: category___slug)
    }
  }
`;
